<template>
  <b-card
    title="Listado de permisos por modulo"
  >
    <b-table-simple
      :sticky-header="false"
      :no-border-collapse="true"
      hover
      caption-top
      responsive
      class="rounded-bottom mb-0"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th colspan="2">
            Listado de modulos
          </b-th>
          <b-th colspan="5">
            Permisos
          </b-th>
        </b-tr>
        <b-tr>
          <b-th>Nombre</b-th>
          <b-th>Crear</b-th>
          <b-th>Actualizar</b-th>
          <b-th>Eliminar</b-th>
          <b-th>Lectura</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(permission, key) in permissions"
          :key="key"
        >
          <b-th>
            {{ $t(permission.label) }}
          </b-th>

          <b-td
            v-for="(permi, j) in permission.children"
            :key="j"
          >
            <b-form-checkbox-group
              v-model="selected"
              name="flavour-2"
              class="demo-inline-spacing"
            >
              <b-form-checkbox
                :value="permi.id"
                plain
              >
                {{ $t(permi.label) }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-td
            colspan="7"
            variant="secondary"
            class="text-right"
          >
            Totat: <b>{{ permissions.length }}</b>
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot, BFormCheckbox, BFormCheckboxGroup, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  props: {
    permissions: {
      type: Array,
      require: false,
      default: () => [],
    },
    permissionsByRol: {
      type: Array,
      require: false,
      default: () => [],
    },
  },
  data() {
    return {
      selected: this.permissionsByRol,
    }
  },
  watch: {
    selected(newValue) {
      this.$emit('new-value', newValue)
    },
  },
}
</script>
