<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-card
        :title="title"
      >
        <validation-observer
          ref="RolForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Rol"
                  label-for="rol-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Rol"
                    vid="name"
                    rules="required|alpha"
                  >
                    <b-input-group>
                      <b-form-input
                        id="rol-name"
                        v-model="name"
                        placeholder="Escribe el nombre del rol"
                        :state="errors.length > 0 ? false:null"
                        name="rol-name"
                      />
                      <b-input-group-append>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          variant="primary"
                          class="mr-1"
                          :disabled="invalid"
                          @click="save"
                        >
                          Guardar
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mb-5"
              >
                <table-permission
                  :permissions="options"
                  :permissions-by-rol="value"
                  @new-value="setValue"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alpha } from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import tablePermission from './tablePermission.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    'table-permission': tablePermission,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: null,
      value: [],
      options: [],
      permissionsByRole: null,
      // validation rules
      required,
      alpha,
    }
  },
  computed: {
    title() {
      return this.$route.params.id ? 'Editar rol' : 'Crear rol'
    },
  },
  created() {
    this.getCurrentRole()
  },
  methods: {
    setValue(value) {
      this.value = value
    },
    async getCurrentRole() {
      let permissionsCategorized = null
      if (this.$route.params.id) {
        const rolName = await this.getRole(this.$route.params.id)
        this.name = rolName.rol
        this.permissionsByRole = rolName.permission_by_rol
        permissionsCategorized = this.getCategoriesWithPermissions(rolName.permission_all)
        this.options = this.getOptionStructure(permissionsCategorized)
      } else {
        const permisosName = await this.getPermissionsAll()
        permissionsCategorized = this.getCategoriesWithPermissions(permisosName)
        this.options = this.getOptionStructure(permissionsCategorized)
      }
    },
    getRole(id) {
      return this.$http.post(`/v1/roles/${id}`).then(res => {//eslint-disable-line
        return res.data
      })
    },
    getPermissionsAll() {
      return this.$http.get(`/v1/roles/permissions/`).then(res => {//eslint-disable-line
        return res.data
      })
    },
    getCategoriesWithPermissions(permissions) {
      const permissionsCategorized = {}
      for (let row of permissions) {//eslint-disable-line
        let codenameSplit = row.slug.split('_')//eslint-disable-line
        let subject = codenameSplit[1]//eslint-disable-line
        let category = `roles.${subject}`//eslint-disable-line
        let permissionOption = {//eslint-disable-line
          id: row.id,
          label: `roles.${row.slug}`,
        }
        if (permissionsCategorized[category]) {
          permissionsCategorized[category].push(permissionOption)
        } else {
          permissionsCategorized[category] = [permissionOption]
        }
      }
      if (this.permissionsByRole != null) {
        for (let row of this.permissionsByRole) {//eslint-disable-line
          let findedPermission = permissions.find(//eslint-disable-line
            permissionsRol => permissionsRol.id === row.id,
          )//eslint-disable-line
          if (findedPermission) {
            this.value.push(findedPermission.id)
          }
        }
      }
      return permissionsCategorized
    },
    getOptionStructure(permissionsCategorized) {
      let options = []//eslint-disable-line
      for (const category in permissionsCategorized) {//eslint-disable-line
        options.push({
          id: category,
          label: category,
          children: permissionsCategorized[category],
        })
      }
      return options
    },
    save() {
      this.$refs.RolForm.validate().then(success => {
        if (success) {
          if (this.$route.params.id) {
            this.$http.put(`/v1/roles/${this.$route.params.id}`, {
              name: this.name,
              permissions: this.value,
            }).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha actualizado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El rol se ha actualizado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$router.go(-1)
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          } else {
            this.$http.post('/v1/roles/', {
              name: this.name,
              permissions: this.value,
            }).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha creado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El rol se ha guardado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$router.go(-1)
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          }
        }
      })
    },
  },
}
</script>
<style>
.vue-treeselect__multi-value {
  max-height: calc(12vh) !important;
  overflow: auto;
}

.vue-treeselect__multi-value-label {
  font-size: 10px;
  padding: 1px;
}
.vue-treeselect__menu {
  margin-bottom: 20px;
  max-height: calc(74vh - 18rem) !important;
}
</style>
